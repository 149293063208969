<template lang="pug">
structure-input-select(
  :listOptions="listCountries",
  v-model="selectedCountry",
  position="top",
  :label="$t('profile.input_country')"
)
</template>

<script>
export default {
  name: "selectorCountry",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: String,
    },
  },
  data() {
    return {
      selectedCountry: [],
    };
  },
  created() {},
  computed: {
    listCountries() {
      const formated = this.$store.getters["countries/countries"]?.map(
        (country) => {
          return {
            value: country.abbreviation,
            text: country.country,
          };
        }
      );
      return [{ value: "XX", text: "Select Country" }, ...formated];
    },
  },
  methods: {
    changeCountry(lang) {
      this.selectedCountry = this.listCountries.find((l) => l.value == lang);
    },
  },
  watch: {
    modelValue(n) {
      this.changeCountry(n);
    },
    selectedCountry(n) {
      this.$emit("update:modelValue", n.value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
