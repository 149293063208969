<template lang="pug">
structure-h1(:title="$t('profile.title1')")
structure-card
  b {{ $t('profile.box1_title') }}
  p {{ $t('profile.box1_sub_title') }}
  .grid.grid-cols-2.gap-5.mt-10
    structure-input(
      :label="$t('profile.input_name')",
      name="input_name",
      v-model="input_name"
    )
    structure-input(
      :label="$t('profile.input_lastname')",
      name="input_lastname",
      v-model="input_lastname"
    )
    structure-input(
      :label="$t('profile.input_company')",
      name="input_company",
      v-model="input_company"
    )
    structure-input(
      :label="$t('profile.input_identificationnumber')",
      name="input_identificationnumber",
      v-model="input_identificationnumber"
    )
    structure-input(
      :label="$t('profile.input_city')",
      name="input_city",
      v-model="input_city"
    )
    structure-input(
      :label="$t('profile.input_direction')",
      name="input_direction",
      v-model="input_direction"
    )
    selectorCountry(v-model="input_country")
    selectorLanguage(v-model="language")

  .flex.mt-5.justify-start
    structure-button.text-white.bg-blue-600(
      :text="$t('globals.button_save')",
      @click="updateData",
      :class="{ 'opacity-50': button_update_disabled }"
    )
</template>
<script>
import userRepository from "@/repositories/userRepository";
import selectorLanguage from "./selectorLanguage";
import selectorCountry from "./selectorCountry";
export default {
  name: "profile",
  components: {
    selectorLanguage,
    selectorCountry,
  },
  data: () => ({
    input_name: "",
    input_lastname: "",
    input_company: "",
    input_identificationnumber: "",
    input_city: "",
    input_direction: "",
    input_country: "",
    language: "",
    vat_is_valid: false,
  }),

  async created() {
    await this.$store.dispatch("countries/getCountries");
    this.setform();
  },

  computed: {
    button_update_disabled() {
      if (
        this.input_name == "" ||
        this.input_lastname == "" ||
        this.input_company == "" ||
        this.input_identificationnumber == "" ||
        this.input_city == "" ||
        this.input_direction == "" ||
        this.input_country == "" ||
        this.language == "" ||
        this.bloqued_by_vat_valid
      ) {
        return true;
      } else {
        return false;
      }
    },
    bloqued_by_vat_valid() {
      return this.vat_is_valid;
    },
  },

  methods: {
    async setform() {
      const { user, profileBilling } = await userRepository.getFullDataUser();
      if (user.user_language) this.language = user.user_language;
      if (profileBilling) {
        this.input_name = profileBilling.user_name;
        this.input_lastname = profileBilling.user_last_name;
        this.input_company = profileBilling.company_name;
        this.input_identificationnumber = profileBilling.company_vat;
        this.input_city = profileBilling.company_city;
        this.input_country = profileBilling.company_country || "XX";
        this.input_direction = profileBilling.company_address;
        this.vat_is_valid = profileBilling.vat_is_valid;
      }
    },
    async updateData() {
      if (this.bloqued_by_vat_valid) {
        this.$toast.warning(this.$t("profile.message_verified"));
      } else if (this.button_update_disabled) {
        await userRepository
          .updateMe({
            company_name: this.input_company,
            company_vat: this.input_identificationnumber,
            company_address: this.input_direction,
            company_city: this.input_city,
            company_country: this.input_country,
            user_name: this.input_name,
            user_last_name: this.input_lastname,
            user_lang: this.language,
          })
          .then((r) => {
            console.log(r);
            this.$toast.success(this.$t("profile.response_1"));
          })
          .catch(() => {
            this.$toast.error(this.$t("profile.response_bad"));
          })
          .finally(() => this.setform());
      }
      this.setform();
    },
  },
};
</script>
<style lang="scss" scoped>
</style>