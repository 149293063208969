<template lang="pug">
structure-input-select(
  :listOptions="languages_template",
  v-model="selectedLanguage",
  position="top",
  :label="$t('profile.box3_title')"
)
</template>

<script>
import { loadLocaleMessages } from "@/locale/i18n.config";
export default {
  name: "selectorLanguage",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: String,
    },
  },
  data() {
    return {
      selectedLanguage: null,
      languages_template: [
        {
          text: "English",
          id: "en",
        },
        {
          text: "Deutsch",
          id: "de",
        },
        {
          text: "Italiano",
          id: "it",
        },
        {
          text: "Français",
          id: "fr",
        },
        {
          text: "Español",
          id: "es",
        },
        {
          text: "Português",
          id: "pt",
        },
        {
          text: "Nederlands",
          id: "nl",
        },
        {
          text: "Polski",
          id: "pl",
        },
      ],
    };
  },
  created() {
    this.changeLang(localStorage.getItem("iean-locale") || "en");
  },
  methods: {
    changeLang(lang) {
      this.selectedLanguage = this.languages_template.find((l) => l.id == lang);
    },
  },
  watch: {
    modelValue(n) {
      this.changeLang(n);
    },
    selectedLanguage(n) {
      localStorage.setItem("iean-locale", n.id);
      loadLocaleMessages(n.id);
      this.$emit("update:modelValue", n.id);
    },
  },
};
</script>

<style lang="scss" scoped></style>
